import React from 'react';
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntTooltip, Space, Typography } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { UnitLabels } from '../../models/constants';
import { ChartKPIs, KPIConfig } from '../../models/kpi.model';

import './metrics-bar-chart.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const { Text } = Typography;
const formatter = Intl.NumberFormat('en', { notation: 'compact' });

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        // eslint-disable-next-line
        callback: (value: any) => formatter.format(value),
        count: 6,
      },
    },
  },
};

interface Props {
  chartData: ChartKPIs[];
  labels: string[];
  dataKey: keyof ChartKPIs;
  kpiConfig: KPIConfig[];
  prevChartData: ChartKPIs[];
}
export const MetricsBarChart = ({
  chartData,
  kpiConfig,
  dataKey,
  labels,
  prevChartData,
}: Props) => {
  const config = kpiConfig.find((kpi) => kpi.kpiName === dataKey) || {
    nameInDashboard: dataKey,
    tooltipText: '',
    upColor: 'green',
    downColor: 'red',
    unit: 'Number',
  };

  const data = {
    labels,
    datasets: [
      {
        label: config.nameInDashboard,
        data: chartData.map((kpi) => parseFloat(kpi[dataKey])),
        backgroundColor: 'rgba(21, 137, 238, 1)',
        barPercentage: 0.3,
      },
    ],
  };
  const aggregatedKpiValue = data?.datasets[0].data?.reduce((sum, kpi) => sum + kpi, 0);
  const prevAggregatedValue = prevChartData.length
    ? prevChartData?.map((kpi) => parseInt(kpi[dataKey], 10))?.reduce((sum, kpi) => sum + kpi, 0)
    : null;
  let aggregatedKpiText;
  if (config?.unit !== 'Percentage') {
    aggregatedKpiText = aggregatedKpiValue.toLocaleString('en-US', {
      maximumFractionDigits: 0,
    });
  } else {
    aggregatedKpiText = aggregatedKpiValue.toLocaleString('en-US', {
      maximumFractionDigits: 1,
    });
  }

  if (config?.unit === 'Currency') {
    aggregatedKpiText = `${aggregatedKpiText} ${chartData[0].currency_code}`;
  } else {
    aggregatedKpiText += UnitLabels[config.unit];
  }
  const percentageChange =
    prevAggregatedValue && ((aggregatedKpiValue - prevAggregatedValue) / prevAggregatedValue) * 100;
  return (
    <div className="metrics-bar-chart">
      <div className="sub-kpis">
        <Text className="card-text">{`${config.nameInDashboard.substring(0, 30)}
               ${config.nameInDashboard.length > 30 ? '...' : ''}`}</Text>
        <AntTooltip className="cursor-pointer" title={config?.tooltipText}>
          <InfoCircleOutlined />
        </AntTooltip>
      </div>
      <Space>
        <Text className="kpi-value">
          {Number.isNaN(aggregatedKpiValue) ? 'N/A' : aggregatedKpiText}
        </Text>
        {!!percentageChange && (
          <span
            style={{
              color: percentageChange > 0 ? config?.upColor : config?.downColor,
              display: 'flex',
            }}
          >
            {percentageChange > 0 ? (
              <CaretUpOutlined className="arrow" />
            ) : (
              <CaretDownOutlined className="arrow" />
            )}
            <Text
              style={{
                color: percentageChange > 0 ? config?.upColor : config?.downColor,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {`${Math.abs(percentageChange).toFixed(1)}%`}
            </Text>
          </span>
        )}
      </Space>

      <div className="chart">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};
