import { useQuery } from '@tanstack/react-query';
import { AccountManagerHierarchy, SalesforceMessage } from '../../models/am-hierarchy.model';

async function waitForMessage(): Promise<AccountManagerHierarchy> {
  return new Promise((resolve) => {
    function onMessage(event: MessageEvent<SalesforceMessage>) {
      if (event?.data?.accountManagerHierarchy) {
        resolve(event.data.accountManagerHierarchy);
        window.removeEventListener('message', onMessage);
      }
    }
    window.addEventListener('message', onMessage);
  });
}

export const useAccountManagerHierarchy = () => {
  const {
    isLoading,
    isError,
    data: accountManagerHierarchy,
  } = useQuery<AccountManagerHierarchy, Error>(['account-manager-hierarchy'], async () => {
    const message = await waitForMessage();
    return message;
  });

  return { accountManagerHierarchy, amHierarchyLoading: isLoading, amHierarchyError: isError };
};
