/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CellContext,
  ColumnDef,
  HeaderContext,
  SortingState,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TableColumnHeader } from '../../components/table-column-header/table-column-header';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { PortfolioTableData, HybridKPIConfig } from '../../models/kpi.model';
import { getIsInvalid, getToken, setSessionToken } from '../../services/auth/auth-slice';
import { getTextTrimToColumnSize } from '../../shared/helper';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { triggerSalesforceEvent } from '../../shared/useAnalytics';
import { useAccountManagerHierarchy } from '../portfolio-overview/useAccountManagerHierarchy';
import { useHybridKpiConfig } from './useHybridKpiConfig';

const DATE_FORMAT = 'YYYY-MM-DD';

interface ApiResponse {
  status: string;
  data: Array<{
    kpiId: string;
    kpiValue: string;
    grid: string;
    vendorName: string;
  }>;
}

export const tabs: { [index: string]: string[] } = {
  commercial: [
    'ID_0001', // Orders
    'ID_0023', // Orders by New customers
    'ID_0024', // Orders by Repeat customers
    'ID_0025', // Revenue Loss / Lost Revenue Due To Cancellation
    'ID_0003', // GMV
    'ID_0009', // NCR or Ad revenue
    'ID_0029', // NCR/GMV%
    'ID_0030', // CPC/GMV%
    'ID_0031', // Joker/GMV%
    'ID_0059', // BestSeller/GMV%
    'ID_0060', // gmv_trpo_local/gmv
  ],
  operational: [
    'ID_0026', // vendor_fail_rate
    'ID_0028', // open_time_rate
    'ID_0027', // vendor_delay_rate
    'ID_0036', // avg_preparation_time
    'ID_0022', // contact_rate
    'ID_0054', // DLP
    'ID_0055', // DLD
  ],
  common: ['serial_no', 'sf_grid_id', 'vendor_name'],
};

const fetchData = async (params: {
  dateRange: { from: MomentDate; to: MomentDate };
  accountOwnerIds: string[];
  accountManagerHierarchy: { geId: string; role: string };
  searchText: string;
  filters: { verticals: string[]; brands: string[]; groups: string[]; cities: string[] };
  vdToken: string;
  kpiIds: string[];
}) => {
  const {
    dateRange,
    accountOwnerIds,
    accountManagerHierarchy,
    searchText,
    filters,
    vdToken,
    kpiIds,
  } = params;
  const response = await axios.get<ApiResponse>(
    `/vendor-dashboard-service/hybrid-kpis/portfolio-overview`,
    {
      params: {
        from: dateRange?.from?.format(DATE_FORMAT),
        to: dateRange?.to?.format(DATE_FORMAT),
        user_ids: accountOwnerIds.join(','),
        ge_id: accountManagerHierarchy.geId,
        role: accountManagerHierarchy.role,
        groups: filters.groups.join(','),
        brands: filters.brands.join(','),
        cities: filters.cities.join(','),
        verticals: filters.verticals.join(','),
        kpi_ids: kpiIds.join(','),
        search: searchText,
      },
      headers: {
        'x-vendorDashboard-token': vdToken,
      },
    },
  );
  return response.data;
};

const columnHelper = createColumnHelper<PortfolioTableData>();

const Header = (info: HeaderContext<PortfolioTableData, string>, value: string) => (
  <TableColumnHeader value={value} info={info} />
);

// TODO : CHECK REDIRECTION TO ACCOUNT PAGE WITH grid
const VendorNameCell = (info: CellContext<PortfolioTableData, string>) => {
  const { getValue, column, row } = info;
  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      onClick={() => {
        triggerSalesforceEvent('PortfolioOverview', 'navigation', {
          recordId: row.original.grid,
        });
      }}
      className="tooltip"
    >
      <span className="tooltip-text">{` ${getValue()}`}</span>
      <span className="link">{getTextTrimToColumnSize(getValue(), column.getSize(), 9)}</span>
    </div>
  );
};

const Cell = (value: string | number) => <>{value}</>;

const KPICell = (value: string) => {
  const formattedValue = Number.isNaN(parseFloat(value))
    ? 'N/A'
    : parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits: 1,
      });

  return (
    <div className="cell">
      <span className="cell-metric-value">{formattedValue}</span>
    </div>
  );
};

const createColumnDefs = (tab: string[], kpiConfigs: HybridKPIConfig[]) => {
  return tab.map((kpiId) => {
    if (kpiId === 'serial_no') {
      return columnHelper.accessor('no', {
        id: 'serial_no',
        size: 30,
        header: () => '',
        cell: (info) =>
          Cell(
            (
              (info.table
                .getSortedRowModel()
                ?.flatRows?.findIndex((flatRow) => flatRow.id === info.row.id) || 0) + 1
            ).toString(),
          ),
      });
    }
    if (kpiId === 'sf_grid_id') {
      return columnHelper.accessor('grid', {
        id: 'sf_grid_id',
        size: 80,
        header: (info) => Header(info, 'Grid'),
        cell: (info) => Cell(info.getValue()),
      });
    }
    if (kpiId === 'vendor_name') {
      return columnHelper.accessor('vendorName', {
        id: 'vendor_name',
        size: 130,
        header: (info) => Header(info, 'Vendor Name'),
        cell: (info) => VendorNameCell(info),
      });
    }
    const kpiConfig = kpiConfigs.find((config) => config.kpiId === kpiId);
    const kpiName = kpiConfig?.displayName || kpiConfig?.kpiName || kpiId;
    return columnHelper.accessor(kpiId as string, {
      id: kpiId,
      size: 120,
      header: (info) => <TableColumnHeader value={kpiName} info={info} />,
      cell: (info) => KPICell(info.getValue()),
    });
  });
};

export const usePortfolioOverviewTable = (
  view: string,
  sfToken: string,
  state: {
    accountOwnerIds: string[];
    searchText: string;
    dateRange: {
      to: MomentDate;
      from: MomentDate;
      precision: 'month' | 'quarter' | 'day';
    };
    filters: {
      verticals: string[];
      brands: string[];
      groups: string[];
      cities: string[];
    };
    tab: 'commercial' | 'operational' | 'all';
  },
  setState: React.Dispatch<
    React.SetStateAction<{
      accountOwnerIds: string[];
      searchText: string;
      dateRange: {
        to: MomentDate;
        from: MomentDate;
        precision: 'month' | 'quarter' | 'day';
      };
      filters: {
        verticals: string[];
        brands: string[];
        groups: string[];
        cities: string[];
      };
      tab: 'commercial' | 'operational' | 'all';
    }>
  >,
) => {
  const isInvalidSession = useAppSelector(getIsInvalid);
  const dispatch = useAppDispatch();
  const vdToken = useSelector(getToken);
  const { accountManagerHierarchy } = useAccountManagerHierarchy();
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'vendor_name',
      desc: false,
    },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const { kpiConfigs = [] } = useHybridKpiConfig();

  const fetchDataParams = useMemo(() => {
    if (!accountManagerHierarchy) {
      return null;
    }

    return {
      dateRange: state.dateRange,
      accountOwnerIds: state.accountOwnerIds,
      accountManagerHierarchy,
      searchText: state.searchText,
      filters: state.filters,
      vdToken,
      kpiIds: state.tab === 'all' ? kpiConfigs.map((config) => config.kpiId) : tabs[state.tab],
    };
  }, [
    accountManagerHierarchy,
    state.dateRange,
    state.accountOwnerIds,
    state.searchText,
    state.filters,
    state.tab,
    vdToken,
    kpiConfigs,
  ]);

  const { data, error, isLoading, isRefetching } = useQuery(
    ['fetchData', fetchDataParams],
    () => fetchData(fetchDataParams!),
    {
      enabled:
        !!kpiConfigs.length &&
        !!vdToken &&
        !isInvalidSession &&
        !!state.accountOwnerIds.length &&
        !!accountManagerHierarchy?.geId &&
        !!accountManagerHierarchy?.role,
    },
  );

  useEffect(() => {
    if (view === AMC_VIEWS.ACOOUNT_MANAGER) {
      setState((prevState) => ({
        ...prevState,
        accountOwnerIds: [
          new URLSearchParams(window.location.search).get('account_owner_id') || '',
        ],
      }));
    }
    dispatch(setSessionToken(sfToken));
  }, [dispatch, setState, sfToken, view]);

  const transformedData = useMemo(() => {
    const dataMap: Record<string, PortfolioTableData> = {};

    data?.data.forEach((item) => {
      const key = `${item.grid}-${item.vendorName}`;
      if (!dataMap[key]) {
        dataMap[key] = {
          no: '0',
          grid: item.grid,
          vendorName: item.vendorName,
        };
      }
      dataMap[key][item.kpiId] = item.kpiValue;
    });

    return Object.values(dataMap);
  }, [data]);

  const combinedColumns = useMemo(() => {
    const kpiIds = kpiConfigs.map((config) => config.kpiId).sort();
    const columns = createColumnDefs(kpiIds, kpiConfigs);
    const commonColumns = createColumnDefs(tabs.common, kpiConfigs);
    return [...commonColumns, ...columns];
  }, [kpiConfigs]);

  const columns: ColumnDef<PortfolioTableData, string>[] = useMemo(
    () => combinedColumns,
    [combinedColumns],
  );

  const table = useReactTable({
    data: transformedData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // provide a sorting row model
  });

  useEffect(() => {
    if (kpiConfigs?.length) {
      const initialColumnVisibility: VisibilityState = table
        .getAllColumns()
        .reduce((visibilityState, column) => {
          if (state.tab === 'all') {
            visibilityState[column.id] = kpiConfigs
              .map((config) => config.kpiId)
              .concat(tabs.common)
              .includes(column.id);
            return visibilityState;
          }
          visibilityState[column.id] = tabs[state.tab]
            .filter((t) => kpiConfigs.some((kpi) => kpi.kpiId === t))
            .concat(tabs.common)
            .includes(column.id);
          return visibilityState;
        }, {} as VisibilityState);
      setColumnVisibility(initialColumnVisibility);
    }
  }, [kpiConfigs, state.tab, table]);

  return {
    table,
    isLoading,
    isRefetching,
    error,
  };
};
