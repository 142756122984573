import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MomentDate } from '../../models/date.model';
import { ChartKPIs } from '../../models/kpi.model';
import { getToken, getIsInvalid } from '../../services/auth/auth-slice';
import { useAppSelector } from '../../shared/hooks';
import { useAccountManagerHierarchy } from './useAccountManagerHierarchy';

const DATE_FORMAT = 'YYYY-MM-DD';

export const useKpiByMonth = (
  accountOwnerIds: string[],
  dateRange: {
    to: MomentDate;
    from: MomentDate;
    precision: 'month' | 'quarter' | 'day';
  },
  filters: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  },
  searchText: string,
) => {
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  let prevDateRange: {
    to: MomentDate;
    from: MomentDate;
  };
  const { accountManagerHierarchy } = useAccountManagerHierarchy();

  if (dateRange.precision === 'month') {
    prevDateRange = {
      from: dateRange.from!.clone().subtract(1, 'months'),
      to: dateRange.to!.clone().subtract(1, 'months').endOf('month'),
    };
  } else if (dateRange.precision === 'quarter') {
    prevDateRange = {
      from: dateRange.from!.clone().subtract(3, 'months').startOf('quarter'),
      to: dateRange.to!.clone().subtract(3, 'months').endOf('quarter'),
    };
  } else {
    prevDateRange = {
      from: dateRange.from!.clone().subtract(dateRange.to?.diff(dateRange.from, 'days'), 'days'),
      to: dateRange.to!.clone().subtract(dateRange.to?.diff(dateRange.from, 'days'), 'days'),
    };
  }
  const monthNames = moment.monthsShort();
  const {
    isLoading: chartDataLoading,
    isError: chartDataError,
    isFetching: chartDataFetching,
    data: chartData,
  } = useQuery<ChartKPIs[], Error>(
    ['kpi-by-month', dateRange.from, dateRange.to, accountOwnerIds, vdToken, filters, searchText],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/kpi-by-month?from=${dateRange?.from?.format(
          DATE_FORMAT,
        )}&to=${dateRange?.to?.format(DATE_FORMAT)}&user_ids=${accountOwnerIds}&ge_id=${
          accountManagerHierarchy?.geId
        }&role=${accountManagerHierarchy?.role}&groups=${
          filters.groups
        }&search=${searchText}&brands=${filters.brands}&cities=${filters.cities}&verticals=${
          filters.verticals
        }`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        !!accountManagerHierarchy?.geId &&
        !!accountManagerHierarchy?.role,
    },
  );

  const { isError: prevChartDataError, data: prevChartData } = useQuery<ChartKPIs[], Error>(
    [
      'prev-kpi-by-month',
      prevDateRange.from,
      prevDateRange.to,
      accountOwnerIds,
      vdToken,
      filters,
      searchText,
    ],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/kpi-by-month?from=${prevDateRange?.from?.format(
          DATE_FORMAT,
        )}&to=${prevDateRange?.to?.format(DATE_FORMAT)}&user_ids=${accountOwnerIds}&ge_id=${
          accountManagerHierarchy?.geId
        }&role=${accountManagerHierarchy?.role}&search=${searchText}&groups=${
          filters.groups
        }&brands=${filters.brands}&cities=${filters.cities}&verticals=${filters.verticals}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        !!accountManagerHierarchy?.geId &&
        !!accountManagerHierarchy?.role,
    },
  );

  const labels = chartData?.map((kpi: { month: string; year: string }) => {
    return `${monthNames[parseInt(kpi.month, 10) - 1]} ${parseInt(kpi.year, 10)}`;
  });
  return {
    chartData,
    chartDataLoading: chartDataLoading || chartDataFetching,
    chartDataError: chartDataError && prevChartDataError,
    prevChartData,
    labels,
  };
};
