import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { HybridKPIConfig } from '../../models/kpi.model';
import { getIsInvalid, getToken } from '../../services/auth/auth-slice';
import { useAppSelector } from '../../shared/hooks';
import { useAccountManagerHierarchy } from '../portfolio-overview/useAccountManagerHierarchy';

export const useHybridKpiConfig = () => {
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  const { accountManagerHierarchy } = useAccountManagerHierarchy();
  /* eslint-disable react-hooks/exhaustive-deps */
  const {
    isLoading,
    isError,
    data: kpiConfigs,
  } = useQuery<HybridKPIConfig[], Error>(
    ['kpi-config', accountManagerHierarchy?.geId],
    async () => {
      const { data } = await axios.request({
        method: 'get',
        url: `/vendor-dashboard-service/kpi-configs?geId=${accountManagerHierarchy?.geId}`,
        headers: {
          'x-vendorDashboard-token': vdToken,
          'X-API-Version': '2', // Added API version header
        },
      });
      return data.data;
    },
    { enabled: !!vdToken && !isInvalidSession && !!accountManagerHierarchy?.geId },
  );
  return { kpiConfigs, kpiConfigLoading: isLoading, kpiConfigError: isError };
};
