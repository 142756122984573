interface ColumnItem {
  id: string;
  name: string;
  children?: ColumnItem[];
}

interface Columns {
  [key: string]: ColumnItem[];
}

export const columns: Columns = {
  revenue: [
    { id: 'ID_0001', name: 'orders' },
    { id: 'ID_0023', name: 'new_customers' },
    { id: 'ID_0024', name: 'repeat_customers' },
    { id: 'ID_0025', name: 'revenue_loss' },
    { id: 'ID_0003', name: 'gmv_local' },
    { id: 'ID_0004', name: 'commission_local' },
  ],
  operations: [
    { id: 'ID_0026', name: 'vendor_fail_rate' },
    { id: 'ID_0027', name: 'vendor_delay_rate' },
    { id: 'ID_0036', name: 'avg_preparation_time' },
    { id: 'ID_0022', name: 'contact_rate' },
    { id: 'ID_0028', name: 'open_time_rate' },
    { id: 'ID_0054', name: 'DLP' },
    { id: 'ID_0055', name: 'DLD' },
  ],
  commercial: [
    {
      id: 'ID_0009',
      name: 'local_ncr_revenue',
      children: [
        { id: 'ID_0009', name: 'cpc_revenue_local' },
        { id: 'ID_0033', name: 'cpc_utilized_percentage' },
        { id: 'ID_0032', name: 'roas_on_cpc_percentage' },
        { id: 'ID_0035', name: 'joker_booked' },
        { id: 'ID_0034', name: 'joker_utilized_percentage' },
      ],
    },
    {
      id: 'ID_0029',
      name: 'ncr_gmv_percentage',
      children: [
        { id: 'ID_0030', name: 'cpc_gmv_percentage' },
        { id: 'ID_0031', name: 'joker_gmv_percentage' },
      ],
    },
  ],
};

export function extractIds(obj: Columns): string[] {
  let ids: string[] = [];

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: ColumnItem) => {
        ids.push(item.id);
        if (item.children) {
          ids = ids.concat(extractIds({ [key]: item.children }));
        }
      });
    }
  });
  return ids;
}
