import React from 'react';
import { Space, Tabs, TabsProps, Typography } from 'antd';
import { DataGridData } from '../../../models/kpi.model';
import { KpiCard } from '../../kpi-card/v2/kpi-card';
import { columns } from './card-def';

interface TabViewProps {
  kpiData: DataGridData;
  dataError: string | boolean | undefined;
}
const { Text } = Typography;

export const TabView = ({ kpiData, dataError }: TabViewProps) => {
  const items: TabsProps['items'] = [
    {
      key: 'Revenue',
      label: `Revenue`,
      children: (
        <>
          {dataError || !kpiData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              <Text>
                {dataError ||
                  'No data is available for that period of time. Please choose other dates.'}
              </Text>
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.revenue.map((kpiDefinition) => {
                const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      prevInterval={kpiData.prevInterval}
                      kpi={kpi}
                      key={`kpi-key-${kpi.id}`}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
    {
      key: 'Commercial',
      label: `Commercial`,
      children: (
        <>
          {dataError || !kpiData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              <Text>
                {dataError ||
                  'No data is available for that period of time. Please choose other dates.'}
              </Text>
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.commercial.map((kpiDefinition) => {
                const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      kpi={kpi}
                      key={`kpi-key-${kpi.id}`}
                      prevInterval={kpiData.prevInterval}
                      subKpis={kpiDefinition?.children?.map((def) =>
                        kpiData?.kpis?.find((subKpi) => subKpi.id === def.id),
                      )}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
    {
      key: 'Operations',
      label: `Operations`,
      children: (
        <>
          {dataError || !kpiData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              <Text>
                {dataError ||
                  'No data is available for that period of time. Please choose other dates.'}
              </Text>
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.operations.map((kpiDefinition) => {
                const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      prevInterval={kpiData.prevInterval}
                      kpi={kpi}
                      key={`kpi-key-${kpi.id}`}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
