import { ChartKPIs } from '../../models/kpi.model';

export const tabs: {
  [index: string]: (keyof ChartKPIs)[];
} = {
  commercial: [
    'successful_orders',
    'abv',
    'gmv_local',
    'ami_gmv_local',
    'ad_revenue',
    'revenue_loss',
    'gfv',
    'ami_successful_orders',
  ],
  operational: [
    'vendor_failure_rate',
    'open_time_per',
    'vendor_delay_rate',
    'avg_preparation_time',
    'contact_rate',
  ],
};
