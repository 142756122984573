export interface KPI {
  name: string;
  value: string;
  nameInDashboard: string;
  previousValue: string;
  percentageChange: string;
  tooltipText: string;
  downColor: string;
  upColor: string;
  id: string;
  unit: string;
  type: string;
}
export interface HybridKPIConfig {
  kpiId: string;
  unit: string;
  type: string;
  kpiName: string;
  displayName: string;
  toolTipText: string;
  upColor: string;
  downColor: string;
}

export interface KPIConfig {
  kpiName: string;
  nameInDashboard: string;
  tooltipText: string;
  downColor: string;
  upColor: string;
  unit: string;
}

export interface DataGridData {
  kpis?: [KPI];
  prevInterval: string;
}

export const UnitLabels: { [key: string]: string } = {
  Percentage: '%',
  Time_Sec: ' sec',
  Time_Min: ' min',
  Currency: '',
  Number: '',
};

export interface PortfolioTableData {
  no: string;
  grid: string;
  vendorName: string;
  [kpiId: string]: string; // Index signature for any additional KPI IDs and values
}

export type PortfolioOverviewAxiosResponse = {
  data: PortfolioOverviewAPIResponse;
};
export type PortfolioOverviewAPIResponse = {
  data: PortfolioOverviewKPIs[];
  total: PortfolioOverviewKPIs;
};
export type PortfolioOverviewKPIs = {
  no: string;
  vendorName: string;
  sfGridId: string;
  salesforceId: string;
  fullCount: string;
  successfulOrders: string;
  amiSuccessfulOrders: string;
  successfulOrdersDelta?: string;
  abv: string;
  abvDelta?: string;
  contactRate?: string;
  contactRateDelta?: string;
  newCustomers?: string;
  newCustomersDelta?: string;
  repeatCustomers?: string;
  repeatCustomersDelta?: string;
  revenueLoss?: string;
  revenueLossDelta?: string;
  vendorFailureRate?: string;
  vendorFailureRateDelta?: string;
  vendorDelayRate?: string;
  vendorDelayRateDelta?: string;
  avgPreparationTime?: string;
  avgPreparationTimeDelta?: string;
  openTime?: string;
  openTimeDelta?: string;
  gmv_local?: string;
  ami_gmv_local?: string;
  adRevenue?: string;
  ncrGmvPercentage?: string;
  cpcGmvPercentage?: string;
  jokerGmvPercentage?: string;
  tproGmvPercentage?: string;
  bestsellerGmvPercentage?: string;
  gfv?: string;
  ncrGfvPercentage?: string;
  cpcGfvPercentage?: string;
  jokerGfvPercentage?: string;
};
export type ChartsAPIResponse = {
  data: ChartKPIs[];
};
export type ChartKPIs = {
  ad_revenue: string;
  abv: string;
  gmv_local: string;
  ami_gmv_local: string;
  successful_orders: string;
  ami_successful_orders: string;
  revenue_loss: string;
  vendor_failure_rate: string;
  open_time_per: string;
  vendor_delay_rate: string;
  avg_preparation_time: string;
  contact_rate: string;
  month: string;
  year: string;
  currency_code: string;
  gfv: string;
};

export type HybridChartsAPIResponse = {
  data: {
    kpisByMonth: HybridChartKPIs[];
    kpisAggregated: HybridChartKPIs[];
  };
};
export type HybridChartKPIs = {
  grid: string;
  vendorName: string;
  [kpiId: string]: string;
};
