import React from 'react';
import { InfoCircleOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Typography, Tooltip as AntTooltip } from 'antd';
import Space from 'antd/es/space';
import Spin from 'antd/es/spin';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { options } from '../../components/metrics-bar-chart/metrics-bar-chart';
import { UnitLabels } from '../../models/kpi.model';
import '../../components/charts-container/charts-container.scss';
import { ChartsData } from './useChartKpis';
import { useHybridKpiConfig } from './useHybridKpiConfig';

const { Text, Title } = Typography;
interface Props {
  chartData: ChartsData | undefined;
  prevChartData: ChartsData | undefined;
  loading: boolean;
  error: boolean;
}
export const ChartContainer = ({ chartData, prevChartData, loading, error }: Props) => {
  const monthNames = moment.monthsShort();
  const { kpiConfigs = [] } = useHybridKpiConfig();
  // Group data by KPI ID
  const groupedData = chartData?.kpisByMonth?.reduce((acc, kpi) => {
    if (!acc[kpi.kpi_id]) {
      acc[kpi.kpi_id] = [];
    }
    acc[kpi.kpi_id].push({
      x: `${monthNames[parseInt(kpi.month.toString(), 10) - 1]} ${parseInt(
        kpi.year.toString(),
        10,
      )}`, // Format using moment
      y: kpi.kpi_value, // KPI value for the y-axis
    });
    return acc;
  }, {} as Record<string, { x: string; y: number }[]>);
  const aggregatedValues = chartData?.kpisAggregated?.reduce((acc, kpi) => {
    acc[kpi.kpi_id] = kpi.kpi_value;
    return acc;
  }, {} as Record<string, number>);

  // Create a map for quick lookup of previous aggregated values
  const prevAggregatedValues = prevChartData?.kpisAggregated?.reduce((acc, kpi) => {
    acc[kpi.kpi_id] = kpi.kpi_value;
    return acc;
  }, {} as Record<string, number>);

  if (loading) {
    return (
      <div style={{ height: '230px' }} className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Something went wrong. Please try again later.</Title>
      </div>
    );
  }
  if (!groupedData || !kpiConfigs?.length) {
    return (
      <Space direction="vertical" align="start">
        <Text>
          No data is available for that period of time to populate charts. Please choose other
          dates..
        </Text>
        <img alt="" src="/illustration.png" />
      </Space>
    );
  }

  return (
    <div className="chart-container">
      {Object.keys(groupedData).map((kpi_id) => {
        const aggregatedKpiValue = aggregatedValues ? aggregatedValues[kpi_id] : 0;
        const prevAggregatedValue = prevAggregatedValues ? prevAggregatedValues[kpi_id] : 0;
        const percentageChange =
          prevAggregatedValue &&
          ((aggregatedKpiValue - prevAggregatedValue) / prevAggregatedValue) * 100;
        const config = kpiConfigs.find((kpi) => kpi.kpiId === kpi_id) || {
          kpiId: kpi_id,
          displayName: kpi_id,
          toolTipText: '',
          upColor: 'green',
          downColor: 'red',
          unit: 'Number',
          type: '',
          kpiName: '',
        };
        let aggregatedKpiText;
        if (config?.type !== 'percent') {
          aggregatedKpiText = aggregatedKpiValue?.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          });
        } else {
          aggregatedKpiText = aggregatedKpiValue?.toLocaleString('en-US', {
            maximumFractionDigits: 1,
          });
        }

        if (config?.type === 'currency') {
          aggregatedKpiText = `${aggregatedKpiText} ${config.unit ? config.unit : ''}`;
        } else {
          aggregatedKpiText += UnitLabels[config.unit] ? UnitLabels[config.unit] : '';
        }
        const data = {
          labels: groupedData[kpi_id].map((d) => d.x),
          datasets: [
            {
              label: config.displayName,
              data: groupedData[kpi_id].map((d) => d.y),
              backgroundColor: 'rgba(21, 137, 238, 1)',
              barPercentage: 0.3,
            },
          ],
        };
        return (
          <div key={kpi_id} className="metrics-bar-chart">
            <div className="sub-kpis">
              <Text className="card-text">{`${
                config.displayName?.substring(0, 30) || config.kpiName?.substring(0, 30)
              }
                     ${config.displayName?.length > 30 ? '...' : ''}`}</Text>
              <AntTooltip className="cursor-pointer" title={config?.toolTipText}>
                <InfoCircleOutlined />
              </AntTooltip>
            </div>
            <Space>
              <Text className="kpi-value">
                {Number.isNaN(aggregatedKpiValue) ? 'N/A' : aggregatedKpiText}
              </Text>
              {!!percentageChange && (
                <span
                  style={{
                    color: percentageChange > 0 ? config?.upColor : config?.downColor,
                    display: 'flex',
                  }}
                >
                  {percentageChange > 0 ? (
                    <CaretUpOutlined className="arrow" />
                  ) : (
                    <CaretDownOutlined className="arrow" />
                  )}
                  <Text
                    style={{
                      color: percentageChange > 0 ? config?.upColor : config?.downColor,
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {`${Math.abs(percentageChange).toFixed(1)}%`}
                  </Text>
                </span>
              )}
            </Space>

            <div className="chart">
              <Bar options={options} data={data} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
