import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { PortfolioOverview } from './pages/portfolio-overview/portfolio-overview';
import { PortfolioOverviewHybrid } from './pages/portfolio-overview-hybrid/portfolio-overview-hybrid';
import { VendorDashboardPage } from './pages/vendor-dashboard/vendor-dashboard-page';
import { VendorDashboardPage as VendorDashboardPageHybridKpis } from './pages/vendor-dashboard/vendor-dashboard-page-v2';
import { persistor, store } from './shared/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
});

/* v2 directory references are for new structure of hybrid kpis launch, once its released to all markets no support for previous version of components and pages will be required */
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/portfolio-overview" element={<PortfolioOverview />} />
              <Route path="/portfolio-overview-hybrid" element={<PortfolioOverviewHybrid />} />
              <Route path="/" element={<VendorDashboardPage />} />
              <Route path="/v2" element={<VendorDashboardPageHybridKpis />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
