import React, { ReactElement, useState } from 'react';
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, MenuProps } from 'antd';
import Dropdown from 'antd/es/dropdown';
import Spin from 'antd/es/spin';
import moment from 'moment';
import '../metrics-tab-view-container.scss';
import { MomentDate } from '../../../models/date.model';
import { useTranslations } from '../../../services/i18n/useTranslations';
import {
  Filter,
  MenuItem,
  getItem,
  getMonthFilterOptionsFromCurrentMonth,
} from '../../../shared/helper';

interface MetricsTabViewContainerProps {
  children: ReactElement;
  onMonthChange: (month: MomentDate) => void;
  kpisLoading: boolean;
}
const EmptySpace = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
export const MetricsTabViewContainer = ({
  children,
  kpisLoading,
  onMonthChange,
}: MetricsTabViewContainerProps) => {
  const { t } = useTranslations();

  const monthFilterOptions: Filter = getMonthFilterOptionsFromCurrentMonth();
  const [dropdownOption, setDropdownOption] = useState(Object.keys(monthFilterOptions)[0]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setDropdownOption(e.key);
    onMonthChange(moment(monthFilterOptions[e.key].start));
  };
  const items: MenuItem[] = [
    ...Object.keys(monthFilterOptions).map((key) =>
      getItem(key, key, key === dropdownOption ? <CheckOutlined /> : <EmptySpace />),
    ),
  ];
  if (kpisLoading) {
    return (
      <div className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="vendor-dashboard-layout">
      <div className="vendor-dashboard-layout-title">
        <div style={{ paddingBottom: '10px', fontSize: '1.125rem' }}>{t.title}</div>
        <Dropdown
          trigger={['click']}
          disabled={kpisLoading}
          menu={{ items, onClick: handleMenuClick }}
        >
          <Button>
            {dropdownOption} <CaretDownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className="vendor-dashboard-layout-container">{children}</div>
    </div>
  );
};
