import React from 'react';
import { HeaderContext } from '@tanstack/react-table';
import { PortfolioTableData, PortfolioOverviewKPIs } from '../../models/kpi.model';
import { getTextTrimToColumnSize } from '../../shared/helper';

import './table-column-header.scss';

interface TableColumnHeaderProps<T> {
  value: string;
  info: HeaderContext<T, string | undefined>;
}

export const TableColumnHeader = <T extends PortfolioTableData | PortfolioOverviewKPIs>({
  value,
  info,
}: TableColumnHeaderProps<T>) => {
  return (
    <div className="table-col-header">
      {info.header.isPlaceholder ? null : (
        <div
          {...{
            className: info.header.column.getCanSort() ? 'cursor-pointer' : '',
            onClick: info.header.column.getToggleSortingHandler(),
          }}
          aria-hidden="true"
        >
          <div className="tooltip">
            {value.length > 12 && <span className="tooltip-text">{value}</span>}
            <span>{getTextTrimToColumnSize(value, info.column.getSize(), 8)}</span>
          </div>
          {{
            asc: (
              <span style={{ paddingLeft: '5px' }}>
                <img width="11px" height="11px" alt="" src="/arrowdown.svg" />
              </span>
            ),
            desc: (
              <span style={{ paddingLeft: '5px' }}>
                <img width="11px" height="11px" alt="" src="/arrowup.svg" />
              </span>
            ),
          }[info.header.column.getIsSorted() as string] ?? null}
          {info.header.column.id !== 'serial_no' && !info.header.column.getIsSorted() && (
            <span className="sort">
              <img width="11px" height="11px" alt="" src="/sort.svg" />
            </span>
          )}
        </div>
      )}
      {info.header.column.getCanResize() && (
        // eslint-disable-next-line
        <div
          onMouseDown={info.header.getResizeHandler()}
          onTouchStart={info.header.getResizeHandler()}
          className={`resizer ${info.header.column.getIsResizing() ? 'isResizing' : ''}`}
        />
      )}
    </div>
  );
};
