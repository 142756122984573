import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Account } from '../../models/account';
import { getToken, getIsInvalid } from '../../services/auth/auth-slice';
import { useAppSelector } from '../../shared/hooks';
import { useAccountManagerHierarchy } from './useAccountManagerHierarchy';

export const useFilterData = (accountOwnerIds: string[]) => {
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  const { accountManagerHierarchy } = useAccountManagerHierarchy();

  const { data: verticals } = useQuery<string[], Error>(
    ['verticals', accountOwnerIds, vdToken, accountManagerHierarchy?.role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/verticals?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        accountManagerHierarchy?.role !== 'AGENT',
    },
  );

  const { data: brands } = useQuery<Account[], Error>(
    ['brands', accountOwnerIds, vdToken, accountManagerHierarchy?.role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/brands?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        accountManagerHierarchy?.role !== 'AGENT',
    },
  );

  const { data: groups } = useQuery<Account[], Error>(
    ['groups', accountOwnerIds, vdToken, accountManagerHierarchy?.role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/groups?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        accountManagerHierarchy?.role !== 'AGENT',
    },
  );

  const { data: cities } = useQuery<string[], Error>(
    ['cities', accountOwnerIds, vdToken, accountManagerHierarchy?.role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/cities?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled:
        !!vdToken &&
        !isInvalidSession &&
        !!accountOwnerIds.length &&
        accountManagerHierarchy?.role !== 'AGENT',
    },
  );

  return {
    filterData: {
      verticals,
      brands,
      groups,
      cities,
    },
  };
};
