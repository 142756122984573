import { Space, Typography, Flex } from 'antd';
import { DataGridData } from '../../../models/kpi.model';
import { KpiCard } from '../../kpi-card/v2/kpi-card';
import { columns } from '../../tab-view/v2/card-def';
import '../section-view.scss';

interface SectionViewProps {
  kpiData: DataGridData;
  dataError: string | boolean | undefined;
}
const { Text } = Typography;

export const SectionView = ({ kpiData, dataError }: SectionViewProps) => {
  return (
    <Flex className="section-view" gap="small" vertical>
      <Flex gap="small" vertical key={1}>
        <Text>Revenue</Text>
        {dataError || !kpiData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {dataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>
            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.revenue.map((kpiDefinition) => {
              const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
              return (
                kpi && (
                  <KpiCard
                    prevInterval={kpiData.prevInterval}
                    kpi={kpi}
                    key={`kpi-key-${kpi.name}`}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
      <Flex gap="small" vertical key={2}>
        <Text>Commercial</Text>
        {dataError || !kpiData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {dataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>

            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.commercial.map((kpiDefinition) => {
              const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
              return (
                kpi && (
                  <KpiCard
                    kpi={kpi}
                    key={`kpi-key-${kpi.id}`}
                    prevInterval={kpiData.prevInterval}
                    subKpis={kpiDefinition?.children?.map((def) =>
                      kpiData?.kpis?.find((subKpi) => subKpi.id === def.id),
                    )}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
      <Flex gap="small" vertical key={3}>
        <Text>Operations</Text>
        {dataError || !kpiData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {dataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>
            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.operations.map((kpiDefinition) => {
              const kpi = kpiData?.kpis?.find((obj) => obj.id === kpiDefinition.id);
              return (
                kpi && (
                  <KpiCard
                    prevInterval={kpiData.prevInterval}
                    kpi={kpi}
                    key={`kpi-key-${kpi.id}`}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
    </Flex>
  );
};
