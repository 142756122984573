import React from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useKpiConfig } from '../../pages/portfolio-overview/useKpiConfig';

interface PortfolioTableCellProps {
  value: string | undefined;
  delta?: string | undefined;
  name: string | undefined;
}
export const PortfolioTableCell = ({ value, delta, name }: PortfolioTableCellProps) => {
  const { kpiConfig = [] } = useKpiConfig();
  const config = kpiConfig.find((kpi) => kpi.kpiName === name) || {
    nameInDashboard: name,
    tooltipText: '',
    upColor: 'green',
    downColor: 'red',
    unit: 'Number',
  };
  let kpiValueText = '';
  if (config?.unit !== 'Percentage' && value) {
    kpiValueText = parseFloat(value)?.toLocaleString('en-US', {
      maximumFractionDigits: 0,
    });
  } else if (value) {
    kpiValueText = parseFloat(value)?.toLocaleString('en-US', {
      maximumFractionDigits: 1,
    });
  }
  return (
    <div className="cell">
      <span className="cell-metric-value">{kpiValueText}</span>
      {delta && delta !== '0' && (
        <span
          style={{
            textAlign: 'end',
            color: parseInt(delta!, 10) > 0 ? 'green' : 'red',
          }}
        >
          {parseInt(delta!, 10) > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
          {`${delta}`}%
        </span>
      )}
      {delta && delta === '0' && <span>{`${delta}`}%</span>}
    </div>
  );
};
