import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment, { Moment as MomentDate } from 'moment';
import { useSelector } from 'react-redux';
import { getToken, getIsInvalid } from '../../services/auth/auth-slice';
import { useAppSelector } from '../../shared/hooks';
import { useAccountManagerHierarchy } from '../portfolio-overview/useAccountManagerHierarchy';
import { useHybridKpiConfig } from './useHybridKpiConfig';

const DATE_FORMAT = 'YYYY-MM-DD';

interface ChartsApiResponse {
  status: string;
  data: ChartsData;
}

export interface ChartsData {
  kpisByMonth: {
    kpi_id: string;
    month: number;
    year: number;
    kpi_value: number;
  }[];
  kpisAggregated: {
    kpi_id: string;
    kpi_value: number;
  }[];
}

export const tabs: { [index: string]: string[] } = {
  commercial: [
    'ID_0001', // Orders
    'ID_0003', // GMV
    'ID_0009', // NCR or Ad revenue
    'ID_0025', // Revenue Loss / Lost Revenue Due To Cancellation
  ],
  operational: [
    'ID_0026', // vendor_fail_rate
    'ID_0028', // open_time_rate
    'ID_0027', // vendor_delay_rate
    'ID_0036', // avg_preparation_time
    'ID_0022', // contact_rate
  ],
};

export const useChartKpis = (state: {
  accountOwnerIds: string[];
  searchText: string;
  dateRange: {
    to: MomentDate | null;
    from: MomentDate | null;
    precision: 'month' | 'quarter' | 'day';
  };
  filters: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  };
  tab: 'commercial' | 'operational' | 'all';
}) => {
  const isInvalidSession = useAppSelector(getIsInvalid);
  const vdToken = useSelector(getToken);
  const { accountManagerHierarchy } = useAccountManagerHierarchy();
  const { kpiConfigs = [] } = useHybridKpiConfig();

  const prevDateRange = useMemo(() => {
    if (state.dateRange.precision === 'month') {
      return {
        from: state.dateRange.from ? state.dateRange.from.clone().subtract(1, 'months') : moment(),
        to: state.dateRange.to
          ? state.dateRange.to.clone().subtract(1, 'months').endOf('month')
          : moment(),
      };
    }
    if (state.dateRange.precision === 'quarter') {
      return {
        from: state.dateRange.from
          ? state.dateRange.from.clone().subtract(3, 'months').startOf('quarter')
          : moment(),
        to: state.dateRange.to
          ? state.dateRange.to.clone().subtract(3, 'months').endOf('quarter')
          : moment(),
      };
    }
    const daysDifference =
      state.dateRange.to && state.dateRange.from
        ? state.dateRange.to.diff(state.dateRange.from, 'days')
        : 0;
    return {
      from: state.dateRange.from
        ? state.dateRange.from.clone().subtract(daysDifference, 'days')
        : moment(),
      to: state.dateRange.to
        ? state.dateRange.to.clone().subtract(daysDifference, 'days')
        : moment(),
    };
  }, [state.dateRange]);

  const fetchKpis = async (from: string | undefined, to: string | undefined) => {
    const { data } = await axios.get<ChartsApiResponse>(
      `/vendor-dashboard-service/hybrid-kpis/portfolio-overview-charts`,
      {
        params: {
          from,
          to,
          user_ids: state.accountOwnerIds.join(','),
          ge_id: accountManagerHierarchy?.geId,
          role: accountManagerHierarchy?.role,
          groups: state.filters.groups.join(','),
          search: state.searchText,
          brands: state.filters.brands.join(','),
          cities: state.filters.cities.join(','),
          verticals: state.filters.verticals.join(','),
          kpi_ids: tabs[state.tab]
            .filter((t) => kpiConfigs.some((kpi) => kpi.kpiId === t))
            .join(','),
        },
        headers: {
          'x-vendorDashboard-token': vdToken,
        },
      },
    );
    return data.data;
  };

  const {
    isLoading: chartDataLoading,
    isError: chartDataError,
    isFetching: chartDataFetching,
    data: chartData,
  } = useQuery<ChartsData, Error>(
    [
      'kpi-by-month',
      state.dateRange.from,
      state.dateRange.to,
      state.accountOwnerIds,
      vdToken,
      state.filters,
      state.searchText,
      state.tab,
    ],
    () =>
      fetchKpis(
        state.dateRange?.from?.format(DATE_FORMAT),
        state.dateRange?.to?.format(DATE_FORMAT),
      ),
    {
      enabled:
        !!kpiConfigs.length &&
        !!vdToken &&
        !isInvalidSession &&
        !!state.accountOwnerIds.length &&
        !!accountManagerHierarchy?.geId &&
        !!accountManagerHierarchy?.role,
    },
  );

  const { isError: prevChartDataError, data: prevChartData } = useQuery<ChartsData, Error>(
    [
      'prev-kpi-by-month',
      prevDateRange.from,
      prevDateRange.to,
      state.accountOwnerIds,
      vdToken,
      state.filters,
      state.searchText,
      state.tab,
    ],
    () => fetchKpis(prevDateRange.from.format(DATE_FORMAT), prevDateRange.to.format(DATE_FORMAT)),
    {
      enabled:
        !!kpiConfigs.length &&
        !!vdToken &&
        !isInvalidSession &&
        !!state.accountOwnerIds.length &&
        !!accountManagerHierarchy?.geId &&
        !!accountManagerHierarchy?.role,
    },
  );

  return {
    chartDataLoading: chartDataLoading || chartDataFetching,
    chartDataError: chartDataError || prevChartDataError,
    chartData,
    prevChartData,
  };
};
